<template>
    <Header title="Villa List" />
    <section class="b_booking_section  top_margin">
        <div class="b_booking_row row_padding main_container">
            <div class="b_booking_col">
                <div class="b_booking_title">
                    <p class="body_heading">Accommodations</p>
                </div>
                <div class="b_booking_block">
                    <div class="b_booking_box" v-for="villa in villaList" :key="villa.villaTypeId">
                        <div class="b_booking_lt">
                            <div class="b_booking_inr_lt">
                                <div class="b_booking_img_slider">
                                    <div class="b_booking_slider_for">
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="villa_01">
                                            </div>
                                        </div>
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="villa_01">
                                            </div>
                                        </div>
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="villa_01">
                                            </div>
                                        </div>
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="villa_01">
                                            </div>
                                        </div>
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="villa_01">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="b_booking_slider_nav">
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="villa_01">
                                            </div>
                                        </div>
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="villa_01">
                                            </div>
                                        </div>
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="villa_01">
                                            </div>
                                        </div>
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="villa_01">
                                            </div>
                                        </div>
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="villa_01">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="b_booking_rt">
                            <div class="b_booking_inr_rt">
                                <div class="b_booking_rt_box_1">
                                    <div class="b_booking_rt_titie">
                                        <p class="body_heading">{{ villa.villa_name }}</p>
                                        <!-- <p class="body_heading">Platinum Villa</p> -->
                                    </div>
                                    <div class="b_booking_text">
                                        <p class="data_sub_heading">Destination. Luxury. Respite.</p>
                                    </div>
                                    <div class="b_booking_vill">
                                        <div class="vila_title_info">
                                            <span> Max Quantity per</span>
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                                                    fill="#153936"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                                                    fill="#153936"></path>
                                            </svg>
                                            <span>01 x</span>
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                                                    fill="#153936"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                                                    fill="#153936"></path>
                                            </svg>
                                            <span>16</span>
                                        </div>
                                    </div>
                                    <div class="b_booking_feture">
                                        <ul class="main_qun__ul">
                                            <li class="main_qun_li">
                                                <img :src="first_img" alt="" class="main_qun_img">
                                                <span>3 Bedroom</span>
                                            </li>
                                            <li class="main_qun_li">
                                                <img :src="second_img" alt="" class="main_qun_img">
                                                <span>3 Bedroom</span>
                                            </li>
                                            <li class="main_qun_li">
                                                <img :src="three_img" alt="" class="main_qun_img">
                                                <span>Swimming pool</span>
                                            </li>
                                            <li class="main_qun_li">
                                                <img :src="four_img" alt="" class="main_qun_img">
                                                <span>Personal Lawn</span>
                                            </li>
                                            <li class="main_qun_li">
                                                <img :src="five_img" alt="" class="main_qun_img">
                                                <span>Living Room</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="b_booking_view">
                                        <router-link class="b_booking_a_view" to="/platinum-villa">
                                            <span>View Vila</span>
                                        </router-link>
                                    </div>
                                </div>
                                <div class="b_booking_rt_box_2">
                                    <div class="b_booking_rating">
                                        <div class="b_booking_rat">
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <span>4.9</span>
                                        </div>
                                        <div class="b_booking_all_review font_12">
                                            <span>12 Reviews</span>
                                        </div>
                                    </div>
                                    <div class="b_booking_person font_12">
                                        <span>{{ daysSinceByDate }} days | </span>
                                        <span>{{ form.total_adult }} adults | </span>
                                        <span>{{ form.total_child }} child</span>
                                    </div>
                                    <div class="b_booking_payment">
                                        <span>Rs. {{ villa.price * form.qty }}</span>
                                    </div>
                                    <div class="b_booking_tax font_12">
                                        <span>+Rs. {{ bookingResult.gst_tax }} taxes and {{
                                            bookingResult.extra_person_charge }} Extra Person charges</span>
                                    </div>
                                    <button type="button" class="b_booking_Reserve site_button"
                                        @click="reservePlatinumVilla">
                                        <span>Reserve</span>
                                    </button>
                                    <div class="b_booking_login">
                                        <a href="#" class="b_booking_login_a font_12">
                                            <span>Login for more detalis</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="b_booking_box">
                        <div class="b_booking_lt">
                            <div class="b_booking_inr_lt">
                                <div class="b_booking_img_slider">
                                    <div class="b_booking_slider_for">
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="villa2">
                                            </div>
                                        </div>
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="villa14">
                                            </div>
                                        </div>
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="villa18">
                                            </div>
                                        </div>
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="villa9">
                                            </div>
                                        </div>
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="villa12">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="b_booking_slider_nav">
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="villa2">
                                            </div>
                                        </div>
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="villa14">
                                            </div>
                                        </div>
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="villa18">
                                            </div>
                                        </div>
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="villa9">
                                            </div>
                                        </div>
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="villa12">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="b_booking_rt">
                            <div class="b_booking_inr_rt">
                                <div class="b_booking_rt_box_1">
                                    <div class="b_booking_rt_titie">
                                        <p class="body_heading">Diamond Villa</p>
                                    </div>
                                    <div class="b_booking_text">
                                        <p class="data_sub_heading">Enjoy luxury like never before!</p>
                                    </div>
                                    <div class="b_booking_vill">
                                        <div class="vila_title_info">
                                            <span> Max Quantity per</span>
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                                                    fill="#153936"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                                                    fill="#153936"></path>
                                            </svg>
                                            <span>01 x</span>
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                                                    fill="#153936"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                                                    fill="#153936"></path>
                                            </svg>
                                            <span>16</span>
                                        </div>
                                    </div>
                                    <div class="b_booking_feture">
                                        <ul class="main_qun__ul">
                                            <li class="main_qun_li">
                                                <img :src="first_img" alt="" class="main_qun_img">
                                                <span>3 Bedroom</span>
                                            </li>
                                            <li class="main_qun_li">
                                                <img :src="second_img" alt="" class="main_qun_img">
                                                <span>3 Bedroom</span>
                                            </li>
                                            <li class="main_qun_li">
                                                <img :src="three_img" alt="" class="main_qun_img">
                                                <span>Swimming pool</span>
                                            </li>
                                            <li class="main_qun_li">
                                                <img :src="four_img" alt="" class="main_qun_img">
                                                <span>Personal Lawn</span>
                                            </li>
                                            <li class="main_qun_li">
                                                <img :src="five_img" alt="" class="main_qun_img">
                                                <span>Living Room</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="b_booking_view">
                                        <router-link class="b_booking_a_view" to="/diamond-villa">
                                            <span>View Vila</span>
                                        </router-link>
                                    </div>
                                </div>
                                <div class="b_booking_rt_box_2">
                                    <div class="b_booking_rating">
                                        <div class="b_booking_rat">
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <span>4.9</span>
                                        </div>
                                        <div class="b_booking_all_review font_12">
                                            <span>12 Reviews</span>
                                        </div>
                                    </div>
                                    <div class="b_booking_person font_12">
                                        <span>{{ daysSinceByDate }} days | </span>
                                        <span>{{ form.total_adult }} adults | </span>
                                        <span>{{ form.total_child }} child</span>
                                    </div>
                                    <div class="b_booking_payment">
                                        <span>Rs. {{ bookingResult.total }}</span>
                                    </div>
                                    <div class="b_booking_tax font_12">
                                        <span>+Rs. {{ bookingResult.gst_tax }} taxes and {{
                                            bookingResult.extra_person_charge }} Extra Person charges</span>
                                    </div>
                                    <button type="button" class="b_booking_Reserve site_button">
                                        <span>Reserve</span>
                                    </button>
                                    <div class="b_booking_login">
                                        <a href="#" class="b_booking_login_a font_12">
                                            <span>Login for more detalis</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="b_booking_box">
                        <div class="b_booking_lt">
                            <div class="b_booking_inr_lt">
                                <div class="b_booking_img_slider">
                                    <div class="b_booking_slider_for">
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="structure1">
                                            </div>
                                        </div>
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="villa14">
                                            </div>
                                        </div>
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="villa18">
                                            </div>
                                        </div>
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="villa9">
                                            </div>
                                        </div>
                                        <div class="b_booking_for_imag">
                                            <div class="b_booking_for_img">
                                                <img :src="villa12">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="b_booking_slider_nav">
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="structure1">
                                            </div>
                                        </div>
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="villa14">
                                            </div>
                                        </div>
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="villa18">
                                            </div>
                                        </div>
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="villa9">
                                            </div>
                                        </div>
                                        <div class="b_booking_nav_imag">
                                            <div class="b_booking_nav_img">
                                                <img :src="villa12">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="b_booking_rt">
                            <div class="b_booking_inr_rt">
                                <div class="b_booking_rt_box_1">
                                    <div class="b_booking_rt_titie">
                                        <p class="body_heading">Cottages Villa</p>
                                    </div>
                                    <div class="b_booking_text">
                                        <p class="data_sub_heading">Vacation Time’s a-calling!</p>
                                    </div>
                                    <div class="b_booking_vill">
                                        <div class="vila_title_info">
                                            <span> Max Quantity per</span>
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                                                    fill="#153936"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                                                    fill="#153936"></path>
                                            </svg>
                                            <span>01 x</span>
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                                                    fill="#153936"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                                                    fill="#153936"></path>
                                            </svg>
                                            <span>16</span>
                                        </div>
                                    </div>
                                    <div class="b_booking_feture">
                                        <ul class="main_qun__ul">
                                            <li class="main_qun_li">
                                                <img :src="first_img" alt="" class="main_qun_img">
                                                <span>3 Bedroom</span>
                                            </li>
                                            <li class="main_qun_li">
                                                <img :src="second_img" alt="" class="main_qun_img">
                                                <span>3 Bedroom</span>
                                            </li>
                                            <li class="main_qun_li">
                                                <img :src="three_img" alt="" class="main_qun_img">
                                                <span>Swimming pool</span>
                                            </li>
                                            <li class="main_qun_li">
                                                <img :src="four_img" alt="" class="main_qun_img">
                                                <span>Personal Lawn</span>
                                            </li>
                                            <li class="main_qun_li">
                                                <img :src="five_img" alt="" class="main_qun_img">
                                                <span>Living Room</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="b_booking_view">
                                        <!-- <a href="#" class="b_booking_a_view"> -->
                                        <router-link class="b_booking_a_view" to="/cottage">
                                            <span>View Vila</span>
                                        </router-link>
                                        <!-- </a> -->
                                    </div>
                                </div>
                                <div class="b_booking_rt_box_2">
                                    <div class="b_booking_rating">
                                        <div class="b_booking_rat">
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <span>4.9</span>
                                        </div>
                                        <div class="b_booking_all_review font_12">
                                            <span>12 Reviews</span>
                                        </div>
                                    </div>
                                    <div class="b_booking_person font_12">
                                        <span>{{ daysSinceByDate }} days | </span>
                                        <span>{{ form.total_adult }} adults | </span>
                                        <span>{{ form.total_child }} child</span>
                                    </div>
                                    <div class="b_booking_payment">
                                        <span>Rs. {{ bookingResult.total }}</span>
                                    </div>
                                    <div class="b_booking_tax font_12">
                                        <span>+Rs. {{ bookingResult.gst_tax }} taxes and {{
                                            bookingResult.extra_person_charge }} Extra Person charges</span>
                                    </div>
                                    <button type="button" class="b_booking_Reserve site_button">
                                        <span>Reserve</span>
                                    </button>
                                    <div class="b_booking_login">
                                        <a href="#" class="b_booking_login_a font_12">
                                            <span>Login for more detalis</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import villa_01 from '../assets/images/inner_page/platinum_villa/G1.jpg';
import villa_02 from '../assets/images/inner_page/platinum_villa/G2.jpg';
import villa_03 from '../assets/images/inner_page/platinum_villa/G3.jpg';
import villa_04 from '../assets/images/inner_page/platinum_villa/G4.jpg';
import villa_05 from '../assets/images/inner_page/platinum_villa/G5.jpg';
import villa_06 from '../assets/images/inner_page/platinum_villa/G6.jpg';
import villa_07 from '../assets/images/inner_page/platinum_villa/G7.jpg';
import villa_08 from '../assets/images/inner_page/platinum_villa/G8.jpg';
import first_img from '../assets/images/User/first_img.png';
import second_img from '../assets/images/User/second_img.png';
import three_img from '../assets/images/User/three_img.png';
import four_img from '../assets/images/User/four_img.png';
import five_img from '../assets/images/User/five_img.png';
import structure1 from '../assets/images/gallery/structure/01-structure.jpg';
import villa2 from '../assets/images/gallery/villas/02-villas.jpg';
import villa3 from '../assets/images/gallery/villas/03-villas.jpg';
import villa4 from '../assets/images/gallery/villas/04-villas.jpg';
import villa5 from '../assets/images/gallery/villas/05-villas.jpg';
import villa6 from '../assets/images/gallery/villas/06-villas.jpg';
import villa7 from '../assets/images/gallery/villas/07-villas.jpg';
import villa8 from '../assets/images/gallery/villas/08-villas.jpg';
import villa9 from '../assets/images/gallery/villas/09-villas.jpg';
import villa10 from '../assets/images/gallery/villas/10-villas.jpg';
import villa11 from '../assets/images/gallery/villas/11-villas.jpg';
import villa12 from '../assets/images/gallery/villas/12-villas.jpg';
import villa13 from '../assets/images/gallery/villas/13-villas.jpg';
import villa14 from '../assets/images/gallery/villas/14-villas.jpg';
import villa15 from '../assets/images/gallery/villas/15-villas.jpg';
import villa16 from '../assets/images/gallery/villas/16-villas.jpg';
import villa17 from '../assets/images/gallery/villas/17-villas.jpg';
import villa18 from '../assets/images/gallery/villas/18-villas.jpg';
import villa19 from '../assets/images/gallery/villas/19-villas.jpg';
import Form from 'vform';
import axios from "axios";
import { isProxy, toRaw } from 'vue';
// import {
//     mapGetters,
//     mapActions
// } from 'vuex';

export default {
    name: 'VillaList',
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            villa_01: villa_01,
            villa_02: villa_02,
            villa_03: villa_03,
            villa_04: villa_04,
            villa_05: villa_05,
            villa_06: villa_06,
            villa_07: villa_07,
            villa_08: villa_08,
            first_img: first_img,
            second_img: second_img,
            three_img: three_img,
            four_img: four_img,
            five_img: five_img,
            structure1: structure1,
            villa2: villa2,
            villa3: villa3,
            villa4: villa4,
            villa5: villa5,
            villa6: villa6,
            villa7: villa7,
            villa8: villa8,
            villa9: villa9,
            villa10: villa10,
            villa11: villa11,
            villa12: villa12,
            villa13: villa13,
            villa14: villa14,
            villa15: villa15,
            villa16: villa16,
            villa17: villa17,
            villa18: villa18,
            villa19: villa19,
            villaList: [],
            form: new Form({
                check_in_date: sessionStorage.getItem('check_in_date'),
                check_out_date: sessionStorage.getItem('check_out_date'),
                total_adult: sessionStorage.getItem('total_adult'),
                total_child: sessionStorage.getItem('total_child'),
                qty: sessionStorage.getItem('qty'),
                booking: {
                    total: 0,
                    gst_tax: 0,
                    extra_person_charge: 0,
                    festival_charge: 0,
                    gst_tax_percent: 0,
                }
            }),
            villaId: {
                platinumVillaId: 2,
                diamondVillaId: 5,
                cottageVillaId: 6,
            },
            bookingResult: {
                total: 0,
                extra_person_charge: 0,
                festival_charge: 0,
                gst_tax: 0,
                gst_tax_percent: 0,
            },
            token: localStorage.getItem('auth_cus_token'),

        };
    },
    mounted() {
        let recaptchaScript2 = document.createElement("script");
        recaptchaScript2.setAttribute(
            "src",
            "./js/slick/slick.js"
        );
        document.head.appendChild(recaptchaScript2);
        let recaptchaScript3 = document.createElement("script");
        recaptchaScript3.setAttribute(
            "src",
            "./js/slick/slick.min.js"
        );
        document.head.appendChild(recaptchaScript3);
        const recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
            "src",
            "./js/script.js"
        );
        document.head.appendChild(recaptchaScript);

        window.scrollTo({ top: 0, behavior: "smooth" });

        this.getBookingResults();

        this.getStoreValue();
    },
    methods: {
        async getBookingResults() {
            this.loader = true
            // await axios.post(process.env.VUE_APP_API_CUS_URL + "/villa-booking-total",
            //     {
            //         "villa_booking": [
            //             {
            //                 "villa_type_id": this.villaId.platinumVillaId,
            //                 "quantity": this.form.qty,
            //                 "person": this.form.total_adult,
            //                 "check_in_date": this.form.check_in_date,
            //                 "check_out_date": this.form.check_out_date
            //             }
            //         ]
            //     },
            //     {
            //         headers: {
            //             "Content-Type": "application/json",
            //             'Accept': "application/json",
            //             "Authorization": `Bearer ${this.token}`
            //         }
            //     })
            axios.post(process.env.VUE_APP_API_CUS_URL + "/search-villa",
                {
                    check_in_date: this.form.check_in_date,
                    check_out_date: this.form.check_out_date,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        'Accept': "application/json",
                        "Authorization": `Bearer ${this.token}`
                    }
                })
                .then((response) => {
                    this.villaList = response.data.data;
                    console.log(this.villaList);
                }).catch((e) => {
                    if (e.response.status == 401) {
                        localStorage.removeItem('auth_otp');
                        localStorage.removeItem('auth_user_type');
                        localStorage.removeItem('auth_cus_token');

                        this.removeLocalstorage();

                        this.$router.push({ name: 'home' });
                        window.location.href = "/";
                    }
                });
            // .then((response) => {
            //     console.log('response: ', response);
            //     this.loader = false;
            //     if (response.data.code == 200) {
            //         this.bookingResult = response.data.data;
            //         this.villa_price = this.bookingResult.total;
            //         if (this.bookingResult) {
            //             this.bookingResult.gst_tax_percent = this.bookingResult.gst_tax ? this.bookingResult.gst_tax : 0;
            //             this.bookingResult.gst_tax = (this.bookingResult.total * this.bookingResult.gst_tax) / 100;
            //             this.bookingResult.total = parseInt(this.bookingResult.total) + parseInt(this.bookingResult.extra_person_charge) + parseInt(this.bookingResult.festival_charge) + parseInt(this.bookingResult.gst_tax);
            //         }
            //     }
            //     else {
            //         this.$toast.error(response.data?.message);
            //     }
            // });
        },
        // ...mapActions(['addPlatinum']),
        reservePlatinumVilla() {
            if (!this.token) {
                localStorage.setItem('id', this.form.id);
                localStorage.setItem('check_in_date', this.form.check_in_date);
                localStorage.setItem('check_out_date', this.form.check_out_date);
                localStorage.setItem('total_adult', this.form.total_adult);
                localStorage.setItem('total_child', this.form.total_child);
                localStorage.setItem('qty', this.form.qty);
                this.getBookingResults();
                localStorage.setItem('gst_tax', this.bookingResult.gst_tax);
                localStorage.setItem('total', this.bookingResult.total);
                localStorage.setItem('extra_person_charge', this.bookingResult.extra_person_charge);
                localStorage.setItem('festival_charge', this.bookingResult.festival_charge);
                localStorage.setItem('gst_tax_percent', this.bookingResult.gst_tax_percent);

                if (this.form.check_in_date.length === 0 || this.form.check_out_date.length === 0) {
                    this.checkInDateError = "block";
                } else if (this.form.total_adult == 0) {
                    this.totalAdultError = "block";
                } else if (this.form.qty == 0) {
                    this.totalQTYError = "block";
                } else {
                    this.popupMobile = !this.popupMobile;
                }

            } else if (this.form.check_in_date.length === 0 || this.form.check_out_date.length === 0) {
                this.checkInDateError = "block";
            } else if (this.form.total_adult == 0) {
                this.totalAdultError = "block";
            } else if (this.form.qty == 0) {
                this.totalQTYError = "block";
            } else {
                // this.dateResult();
                this.form.booking.total = this.bookingResult.total;
                this.form.booking.gst_tax = this.bookingResult.gst_tax;
                this.form.booking.extra_person_charge = this.bookingResult.extra_person_charge;
                this.form.booking.festival_charge = this.bookingResult.festival_charge;
                this.form.booking.gst_tax_percent = this.bookingResult.gst_tax_percent;

                // this.addPlatinum(this.form);
                let rawData = this.allPlatinums;
                if (isProxy(this.allPlatinums)) {
                    rawData = toRaw(this.allPlatinums)
                }
                if (rawData[0].id) {
                    this.$router.push({ name: "dashboard" });
                }
                // this.$router.push({ name: "dashboard", query: { id: this.form.id, qty: this.form.qty, check_in_date: this.form.check_in_date, check_out_date: this.form.check_out_date, total_child: this.form.total_child, person: this.form.total_adult, total: this.bookingResult.total, gst_tax: this.bookingResult.gst_tax } });
            }
        },
        getStoreValue() {
            if (this.allPlatinums) {
                let rawData = this.allPlatinums;
                if (isProxy(this.allPlatinums)) {
                    rawData = toRaw(this.allPlatinums)
                }
                if (rawData[0] && rawData[0].id == 2) {
                    this.form.id = rawData[0].id;
                    this.form.qty = rawData[0].qty;
                    this.form.check_in_date = rawData[0].check_in_date;
                    this.form.check_out_date = rawData[0].check_out_date;
                    this.form.total_child = rawData[0].total_child;
                    this.form.total_adult = rawData[0].total_adult;
                    this.form.bookingResult.total = rawData[0].booking.total;
                    this.form.bookingResult.gst_tax = rawData[0].booking.gst_tax;
                    this.form.bookingResult.extra_person_charge = rawData[0].booking.extra_person_charge;
                    this.form.bookingResult.festival_charge = rawData[0].booking.festival_charge;
                    this.form.bookingResult.gst_tax_percent = rawData[0].booking.gst_tax_percent;
                }
            }
        }
    },
    computed: {
        daysSinceByDate() {
            const currentDate = new Date(this.form.check_out_date)
            const previousDate = new Date(this.form.check_in_date)
            const timeDifference = currentDate.getTime() - previousDate.getTime();
            const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            return daysDifference;
        },
        // mapGetters(['allPlatinums'])
    },
}
</script>